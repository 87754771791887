<template>
  <div v-show="value" class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card pt-6">
      <section class="modal-card-body">
          <div class="message is-warning">
            <slot name="message"></slot>
          </div>
      </section>
      <footer class="modal-card-foot">
          <button class="button is-primary" @click="confirm()">Confirm</button>
          <button class="button is-secondary" @click="close()">Cancel</button>
      </footer>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ConfirmationModal',
    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      close() {
        this.$emit("input", false);
      },
      confirm() {
        this.$emit("confirm");
        this.close();
      }
    }
  }
</script>