<template>
    <div class="user">
        <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
                <li><router-link to="/">Dashboard</router-link></li>
                <li><router-link to="/users">Users</router-link></li>
                <li class="is-active"><a href="#" aria-current="page">{{ user.publicId === null ? 'New User' : user.publicId }}</a></li>
            </ul>
        </nav>

        <article v-if="errorMessage" class="message is-danger">
            <div class="message-header">
                <p>Save Error(s)</p>
                <button class="delete" aria-label="delete" @click="errorMessage = null"></button>
            </div>
            <div class="message-body">
                {{ errorMessage }}
            </div>
        </article>

        <article v-if="account.status !== 1" class="message is-warning">
            <div class="message-body">
                This user's account is not active and all associated functionality is disabled.
            </div>
        </article>

        <fieldset :disabled="isLoading">
            <div class="field is-grouped">
                <div class="control is-expanded">
                    <h1 class="title">User</h1>
                </div>
                <p class="control">
                    <button class="button is-primary" @click="saveUser">
                        Save
                    </button>
                </p>
            </div>

            <div class="field">
                <label class="label">Id</label>
                <p class="has-text-grey-lighter mt-3"> {{ user.id ? user.id + ' (' + user.publicId + ')' : '-' }}</p>
            </div>

            <label class="label">Account Id</label>
            <div class="field has-addons">
                <div v-if="!userId" class="control is-expanded">
                    <input class="input" type="text" placeholder="User account id..." v-model="user.accountId">
                </div>
                <p v-else class="has-text-grey-lighter"> {{ user.accountId }}</p>
                <div v-if="!userId">
                    <AccountDropdown @accountSelected="accountSelected" />
                </div>
            </div>

            <div class="field">
                <label class="label">Email</label>
                <div class="control">
                    <input class="input" type="email" placeholder="User email..." v-model="user.email">
                </div>
            </div>
            <div v-if="!userId" class="columns is-multiline mb-0">
                <div class="field column is-narrow pt-6">
                    <label class="switch-label">
                        <input type="checkbox" class="switch" v-model="sendInvite" @change="sendInviteChanged" />
                        Send Invitation Email
                    </label>
                </div>
                <div class="field column">
                    <label class="label">Password</label>
                    <input class="input" type="password" placeholder="Password..." v-model="user.password1" :disabled="sendInvite">
                </div>
                <div class="field column">
                    <label class="label">Re-enter Password</label>
                    <input class="input" type="password" placeholder="Password..." v-model="user.password2" :disabled="sendInvite">
                </div>
            </div>

            <div class="columns is-multiline mb-0">
                <div class="field column">
                    <label class="label">First Name</label>
                    <div class="control">
                        <input class="input" type="text" placeholder="First name..." v-model="user.firstName">
                    </div>
                </div>

                <div class="field column">
                    <label class="label">Last Name</label>
                    <div class="control">
                        <input class="input" type="text" placeholder="Last name..." v-model="user.lastName">
                    </div>
                </div>
            </div>

            <div class="columns is-multiline">
                <div v-if="!userId" class="field column is-narrow">
                    <label class="label">User Status</label>
                    <p class="has-text-grey-lighter mt-3"> {{ statusDictionary[user.status] }}</p>
                </div>
                <div v-else class="field column is-narrow">
                    <label class="label">User Status</label>
                    <div :class="statusDropdownActive ? 'dropdown is-active' : 'dropdown'">
                        <div class="dropdown-trigger">
                            <button class="button" aria-haspopup="true" aria-controls="dropdown-menu" @click="statusDropdownActive = !statusDropdownActive" @blur="delayCloseStatusDropdown">
                                <span>{{ statusDictionary[user.status] }}</span>
                                <span class="icon is-small">
                                    <font-awesome-icon :icon="['fa', 'angle-down']" />
                                </span>
                            </button>
                        </div>
                        <div class="dropdown-menu" id="dropdown-menu" role="menu">
                            <div class="dropdown-content">
                                <a v-for="(index, statusKey) in statusDictionary" :key="'userStatus' + index"
                                   :class="user.status == statusKey ? 'dropdown-item is-active' : 'dropdown-item'" @click="setUserStatus(statusKey)">
                                    {{ statusDictionary[statusKey] }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field column is-narrow">
                    <label class="label">User Role</label>
                    <div :class="roleDropdownActive ? 'dropdown is-active' : 'dropdown'">
                        <div class="dropdown-trigger">
                            <button class="button" aria-haspopup="true" aria-controls="dropdown-menu" @click="roleDropdownActive = !roleDropdownActive" @blur="delayCloseRoleDropdown">
                                <span>{{ roleDictionary[user.role] }}</span>
                                <span class="icon is-small">
                                    <font-awesome-icon :icon="['fa', 'angle-down']" />
                                </span>
                            </button>
                        </div>
                        <div class="dropdown-menu" id="dropdown-menu" role="menu">
                            <div class="dropdown-content">
                                <a v-for="(index, roleKey) in roleDictionary" :key="'userRole' + index"
                                   :class="user.role == roleKey ? 'dropdown-item is-active' : 'dropdown-item'" @click="setUserRole(roleKey)">
                                    {{ roleDictionary[roleKey] }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field column is-narrow">
                    <label class="label">2FA Method</label>
                    <p v-if="!activeTwoFactorMethodId" class="has-text-grey-lighter mt-3">No Method Configured</p>
                    <button v-else class="button is-primary p-3" @click="showTwoFactorConfirmationModal = !showTwoFactorConfirmationModal">
                        <span>Deactivate 2FA Method</span>
                    </button>
                </div>

                <div class="field column is-narrow">
                    <label class="label">Created</label>
                    <p class="has-text-grey-lighter mt-3">{{ !user.created ? 'N/A' : new Date(user.created).toLocaleString() }}</p>
                </div>

                <div class="field column is-narrow">
                    <label class="label">Updated</label>
                    <p class="has-text-grey-lighter mt-3">{{ !user.updated ? 'N/A' : new Date(user.updated).toLocaleString() }}</p>
                </div>
            </div>

            <div class="columns">
                <div class="field column is-half pt-0">
                    <label class="label">Capacity User Id</label>
                    <div class="control">
                        <input class="input" placeholder="Capacity User Id..." v-model="user.capacityUserId">
                    </div>
                </div>
            </div>

            <ProductPermissions :productAccess="user.productAccess" @accessUpdated="setProductAccess"
                                message="Permissionless users are not currently supported within Foundation. To avoid errors, please make sure at least one permission is selected below." />

            <div>
                <div class="field is-grouped has-top-border">
                    <div class="field is-grouped mt-3">
                        <h1 class="title">Line Permissions</h1>
                        <p class="control mt-1 ml-3">
                            <button v-if="this.user.accountId" class="button is-primary is-small is-rounded p-3" @click="linePermissionModalActive = !linePermissionModalActive">
                                <span class="icon is-small"><font-awesome-icon icon="plus" /></span>
                            </button>
                        </p>
                        <UserLinePermissionModal v-if="this.linePermissionModalActive"
                                                 :accountId="this.user.accountId"
                                                 @customerLineSelected="customerLineSelected"
                                                 @close="linePermissionModalActive = !linePermissionModalActive" />
                    </div>
                </div>

                <div class="table-container">
                    <table class="table is-fullwidth has-top-border has-bottom-border mt-0">
                        <thead>
                            <tr>
                                <th>Phone Number</th>
                                <th>Line Name</th>
                                <th>View</th>
                                <th>Change</th>
                                <th>Execute</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody v-if="user.lineAccess && user.lineAccess.length > 0">
                            <tr v-for="(line, index) in user.lineAccess" :key="'lineAccess' + index">
                                <td>
                                    <div class="field">
                                        <div class="control">
                                            <label>{{line.phoneNumber}}</label>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="field">
                                        <div class="control">
                                            <label>{{line.lineName}}</label>
                                        </div>
                                    </div>
                                </td>

                                <td>
                                    <label class="switch-label">
                                        <input type="checkbox" class="switch" v-model="line.view">
                                    </label>
                                </td>

                                <td>
                                    <label class="switch-label">
                                        <input type="checkbox" class="switch" v-model="line.change">
                                    </label>
                                </td>

                                <td>
                                    <label class="switch-label">
                                        <input type="checkbox" class="switch" v-model="line.execute">
                                    </label>
                                </td>

                                <td>
                                    <button class="button is-white is-inverted is-small" @click="removeLinePermission(index)">
                                        <font-awesome-icon :icon="['fa', 'trash-alt']" />
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div v-if="!user.lineAccess || user.lineAccess.length === 0" class="has-text-centered">
                        <p>No Line Permissions Found</p>
                    </div>
                </div>
            </div>

            <div class="field is-grouped is-grouped-right mt-5">
                <p class="control">
                    <button class="button is-primary" @click="saveUser">
                        Save
                    </button>
                </p>
            </div>
        </fieldset>
        <ConfirmationModal v-model="showConfirmationModal" @confirm="updateUser()">
          <template v-slot:message>
            <div class="message-header">
                  <p>Warning</p>
            </div>
            <div class="message-body">
              <p>
                Note: This user is tied to the account owner. Changing the status
                or permissions on this user will impact the account's
                ability to authenticate with the API.
              </p>
            </div>
          </template>
        </ConfirmationModal>
        <ConfirmationModal v-model="showTwoFactorConfirmationModal" @confirm="deactivateTwoFactorMethod()">
          <template v-slot:message>
            <div class="message-header">
                  <p>Warning</p>
            </div>
            <div class="message-body">
              <p>
                Deactivating the user's 2FA method will prompt the user to set up
                a new 2FA method the next time they log in.
              </p>
            </div>
          </template>
        </ConfirmationModal>
    </div>
</template>

<script>
    import { secureGet, securePatch, securePost, securePut } from '../secureFetch.js';

    import Loading from '../components/Loading';
    import ProductPermissions from '../components/ProductPermissions';
    import AccountDropdown from '../components/AccountDropdown';
    import UserLinePermissionModal from '../components/UserLinePermissionModal';
    import ConfirmationModal from '../components/ConfirmationModal';

    export default {
        name: 'User',
        components: {
            AccountDropdown,
            Loading,
            ProductPermissions,
            UserLinePermissionModal,
            ConfirmationModal,
        },
        computed: {
            hostUrl: function() {
                return this.$store.state.hostUrl;
            },
            // This will need to be updated if we ever allow multiple 2FA methods.
            activeTwoFactorMethodId() {
                const activeMethod = this.user?.userTwoFactorMethods?.find(method => method.isActive);
                return activeMethod ? activeMethod.id : null;
            }
        },
        data() {
            return {
                isLoading: false,
                userId: null,
                user: {
                    status: 3,
                    role: 1,
                    accountId: null,
                    userTwoFactorMethods: [],
                },
                statusDictionary: { 1: 'Active', 2: 'Deleted', 3: 'Pending', 4: 'Inactive' },
                statusDropdownActive: false,
                roleDictionary: { 1: 'Standard', 2: 'Admin', 3: 'Integration', 4: 'Support' },
                roleDropdownActive: false,
                products: [],
                customerLines: [],
                errorMessage: null,
                defaultErrorMessage: "Something didn't work.",
                linePermissionModalActive: false,
                sendInvite: true,
                account: {
                    status: 1,
                },
                showConfirmationModal: false,
                showTwoFactorConfirmationModal: false,
                permissionsOrStatusChanged: false,
            }
        },
        mounted() {
            this.userId = this.$route.params.userId;
            this.getUser();
        },
        methods: {
            getUser() {
                if (this.userId) {
                    this.isLoading = true;

                    secureGet(this.hostUrl + "v1/user/" + this.userId)
                        .then(data => {
                            if (data && data.success) {
                                this.user = data.user;
                                this.permissionsOrStatusChanged = false;

                                this.buildLinePermissionsTable();
                                this.getAccount();
                            }

                            this.isLoading = false;
                        });
                }
            },
            delayCloseStatusDropdown() {
                setTimeout(() => this.statusDropdownActive = false, 500);
            },
            delayCloseRoleDropdown() {
                setTimeout(() => this.roleDropdownActive = false, 500);
            },
            accountSelected(accountId) {
                this.user.accountId = accountId;
            },
            customerLineSelected({ lines, access }){
                if(!this.user.lineAccess) {
                    this.user.lineAccess = [];
                }
                for(let i = 0; i < lines.length; i++) {
                    var lineAccess = {
                        lineId: lines[i].lineId,
                        lineName: lines[i].lineName,
                        phoneNumber: lines[i].phoneNumber,
                        access: access,
                        view: access & 1,
                        change: access & 2,
                        execute: access & 4
                    };

                    if(this.user.lineAccess.findIndex(x => x.lineId === lineAccess.lineId) == -1){
                        this.user.lineAccess.push(lineAccess);
                    }
                }
            },
            setUserStatus(status) {
                this.user.status = parseInt(status);
                this.statusDropdownActive = false;
                this.permissionsOrStatusChanged = true;
            },
            setUserRole(role) {
                this.user.role = parseInt(role);
                this.roleDropdownActive = false;
            },
            saveUser() {
                this.errorMessage = null;

                this.buildLineAccess();

                if (this.userId) {
                    if (this.validateUser(false)) {
                      if (this.permissionsOrStatusChanged && this.user.id === this.account.ownerUserId) {
                        this.showConfirmationModal = true;
                      } else {
                        this.updateUser();
                      }
                    }
                }
                else if (!this.sendInvite && this.validateUser(true) || this.sendInvite && this.validateUser(false)) {
                    this.createUser();
                }
            },
            updateUser() {
                this.isLoading = true;

                securePut(this.hostUrl + "v1/user/" + this.userId, this.user)
                    .then((data) => {
                        this.isLoading = false;

                        if (data.success) {
                            this.getUser();
                        } else {
                            this.errorMessage = data.message;

                            if (!this.errorMessage) {
                                this.errorMessage = this.defaultErrorMessage;
                            }
                        }
                    })
                    .catch((e) => {
                        this.isLoading = false;
                        this.errorMessage = this.defaultErrorMessage;
                        console.error(e);
                    });
            },
            createUser() {
                this.isLoading = true;

                this.user.password = this.user.password1;
                
                securePost(this.hostUrl + "v1/user/create-user", this.user)
                    .then((data) => {
                        this.isLoading = false;

                        if (data.success) {
                            this.userId = data.user.publicId;
                            this.getUser();

                            if (this.sendInvite) {
                                this.sendInviteEmail();
                            }
                        } else {
                            this.errorMessage = data.message;

                            if (!this.errorMessage) {
                                this.errorMessage = this.defaultErrorMessage;
                            }
                        }
                    })
                    .catch((e) => {
                        this.isLoading = false;
                        this.errorMessage = this.defaultErrorMessage;
                        console.error(e);
                    });
            },
            setProductAccess(productAccess) {
                this.user.productAccess = productAccess;
                this.permissionsOrStatusChanged = true;
            },
            removeLinePermission(index) {
                this.user.lineAccess.splice(index, 1);
                this.permissionsOrStatusChanged = true;
            },
            buildLinePermissionsTable() {
                if (this.user.lineAccess) {
                    for (let i = 0; i < this.user.lineAccess.length; ++i) {
                        this.user.lineAccess[i].view = this.user.lineAccess[i].access & 1; 
                        this.user.lineAccess[i].change = this.user.lineAccess[i].access & 2; 
                        this.user.lineAccess[i].execute = this.user.lineAccess[i].access & 4; 
                    }

                    this.getLineDetails();
                }
            },
            buildLineAccess() {
                if (this.user.lineAccess) {
                    for (let i = 0; i < this.user.lineAccess.length; ++i) {
                        this.user.lineAccess[i].access = (this.user.lineAccess[i].view ? 1 : 0) | 
                                                         (this.user.lineAccess[i].change ? 2 : 0) | 
                                                         (this.user.lineAccess[i].execute ? 4 : 0);
                    }
                }
            },
            validateUser(validatePassword) {
                var errorMessages = [];

                if (!this.user.accountId || this.user.accountId.trim() === '') {
                    errorMessages.push('Acount Id is required');
                }

                if (!this.user.email || this.user.email.trim() === '') {
                    errorMessages.push('Email is required');
                }

                if (validatePassword) {
                    if (!this.user.password1 || this.user.password1.trim() === '' ||
                        !this.user.password2 || this.user.password2.trim() === '') {
                        errorMessages.push('Password is required');
                    }
                    else if (this.user.password1 !== this.user.password2) {
                        errorMessages.push('Passwords must match');
                    }
                }

                if (!this.user.productAccess || this.user.productAccess.length < 1) {
                    errorMessages.push('At least one product permission is required');
                }

                if (errorMessages.length > 0) {
                    this.errorMessage = errorMessages.join('; ');
                    document.getElementById("column-right").scrollTo({top: 0, behavior: "smooth"});
                }

                return errorMessages.length === 0;
            },
            getLineDetails(){
                if(this.user.lineAccess) {
                    var postBody = {
                        lineIds: this.user.lineAccess.map(x => x.lineId)
                    };

                    securePost(this.hostUrl + "v1/line/customer-line-details/min", postBody)
                        .then((data) => {
                            if(data){
                                for(let i = 0; i < data.customerLines.length; i++) {
                                    let lineIndex = this.user.lineAccess.findIndex(x => x.lineId === data.customerLines[i].lineId);
                                    if(lineIndex > -1){
                                        this.user.lineAccess[lineIndex].lineName = data.customerLines[i].lineName;
                                        this.user.lineAccess[lineIndex].phoneNumber = data.customerLines[i].phoneNumber;
                                    }
                                }
                            }
                            
                        });
                }
            },
            sendInviteChanged() {
                if (this.sendInvite) {
                    this.user.status = 3;
                    this.user.password1 = '';
                    this.user.password2 = '';
                } else {
                    this.user.status = 1;
                }
            },
            sendInviteEmail() {
                securePost(this.hostUrl + "v1/user/invite-user/" + this.userId)
                    .then((data) => {
                        if (!data.success) {
                            this.errorMessage = data.message;

                            if (!this.errorMessage) {
                                this.errorMessage = this.defaultErrorMessage;
                            }
                        }
                    })
                    .catch((e) => {
                        this.errorMessage = this.defaultErrorMessage;
                        console.error(e);
                    });
            },
            getAccount() {
                this.isLoading = true;

                secureGet(this.hostUrl + "v1/account/" + this.user.accountId)
                    .then(data => {
                        if (data && data.success) {
                            this.account = data.account;
                        }
                        this.isLoading = false;
                    });
            },
            deactivateTwoFactorMethod() {
                securePatch(this.hostUrl + "v1/user/user-two-factor-method/" + this.activeTwoFactorMethodId)
                    .then((data) => {
                        if (data && data.success) {
                            this.user.userTwoFactorMethods.forEach(method => {
                                if (method.id === this.activeTwoFactorMethodId) {
                                    method.isActive = false;
                                    return;
                                }
                            });
                        } else {
                            this.errorMessage = data.message;

                            if (!this.errorMessage) {
                                this.errorMessage = this.defaultErrorMessage;
                            }
                        }
                    })
                    .catch((e) => {
                        this.errorMessage = this.defaultErrorMessage;
                        console.error(e);
                    });
            }
        }
    };
</script>

<style scoped>
    td:last-child {
        width: 1em;
        padding: 0;
        white-space: nowrap;
    }
</style>