<template>
    <div class="modal is-active">
        <div class="modal-background" @click="close"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">User Line Permissions</p>
                <button class="delete" @click="close" aria-label="close"></button>
            </header>
            <section class="modal-card-body" style="height: 400px">
                <div>
                    <b-tag v-for="customerLine in customerLinesSelected" :key="customerLine.id"> {{customerLine.lineName}} </b-tag>
                </div>
                <div class="columns is-multiline">
                    <div class="field column is-narrow">
                        <b-dropdown
                            aria-role="list"
                            scrollable
                            max-height="200"
                            v-model="customerLinesSelected"
                            multiple>
                            <template #trigger>
                                <b-button
                                    type="is-primary"
                                    icon-right="menu-down"
                                    @click="customerLineDropdownSelected">
                                    Selected ({{ customerLinesSelected.length }})
                                </b-button>
                            </template>

                            <b-dropdown-item custom aria-role="listitem">
                                <b-input v-model="customerLineDropdownFilter" placeholder="search" expanded/>
                            </b-dropdown-item>

                            <b-dropdown-item
                            v-for="customerLine in customerLinesFiltered"
                            :key="customerLine.lineName"
                            :value="customerLine"
                            >
                                {{customerLine.lineName}} - {{customerLine.phoneNumber}}
                            </b-dropdown-item>

                        </b-dropdown>
                    </div>
                    <div class="field column is-narrow">
                        <label class="switch-label">
                            <input type="checkbox" class="switch" v-model="view" /> View
                        </label>
                    </div>
                    <div class="field column is-narrow">
                        <label class="switch-label">
                            <input type="checkbox" class="switch" v-model="change" /> Change
                        </label>
                    </div>
                    <div class="field column is-narrow">
                        <label class="switch-label">
                            <input type="checkbox" class="switch" v-model="execute" /> Execute
                        </label>
                    </div>
                </div>
            </section>
            <footer class="modal-card-foot">
                <button class="button is-primary" @click="customerLineSelected">Save changes</button>
                <button class="button is-primary" @click="close">Cancel</button>
            </footer>
        </div>
    </div>
</template>

<script>
    import { securePost } from '../secureFetch.js';

    export default {
        name: 'UserLineModal',
        props: {
            accountId: String
        },data() {
            return {
                customerLines: [],
                customerLinesSelected: [],
                customerLineDropdownFilter: "",
                customerLineDropdownActive: false,
                firstSearch: true,
                view: false,
                change: false,
                execute: false,
                errorMessage: "",
            }
        },
        computed: {
            hostUrl: function() {
                return this.$store.state.hostUrl;
            },
            customerLinesFiltered: function() {
                var customerLineFilter = "";
                if (this.customerLineDropdownFilter) {
                    customerLineFilter = this.customerLineDropdownFilter;
                }
                return this.customerLines.filter(function (customerLine) {
                    return customerLine.lineName.toUpperCase().match(customerLineFilter.toUpperCase()) 
                        || customerLine.phoneNumber.includes(customerLineFilter)
                });
            }
        },
        watch: {
            errorMessage(value) {
                this.$emit("error", value);
            },
        },
        mounted(){
            this.searchCustomerLines();
        },
        methods: {
            delayCloseCustomerLineDropdown() {
                setTimeout(() => this.customerLineDropdownActive = false, 500);
            },
            customerLineDropdownSelected() {
                if(this.firstSearch){
                    this.firstSearch = false;
                    this.searchCustomerLines()
                }
            },
            customerLineSelected() {
                var access = (this.view ? 1 : 0) | (this.change ? 2 : 0) | (this.execute ? 4 : 0);
                this.$emit('customerLineSelected', { lines: this.customerLinesSelected, access: access });
                this.customerLinesSelected = [];
                this.$emit('close', true);
            },
            searchCustomerLines() {
                this.customerLines = [];
                this.errorMessage = null;

                var postBody = {
                    accountId: this.accountId,
                    page: 0,
                    resultsPerPage: 9999
                };

                securePost(this.hostUrl + "v1/line/search-customer-lines", postBody)
                    .then(data => {
                        if (data && data.success) {
                            this.customerLines = data.customerLines.sort((a, b) => a.lineName.localeCompare(b.lineName));
                        }
                        else {
                            this.errorMessage = "Error Retrieving CustomerLines: " + data.message;
                        }
                });
            },
            close(){
                this.customerLinesSelected = [];
                this.$emit('close', true);
            }
        },
    };
</script>

<style scoped>
    .dropdown-content {
        max-height: 300em;
        overflow: auto;
    }
    .modal-card { 
        max-width: 820px; 
        width: fit-content; 
    }
</style>